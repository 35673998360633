import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';
import {
  useGetAllQuestions,
  useGetMyProfile,
  useGetRequestCount,
  useGetResponseForAllQuesionsByUser,
  useGetUsersFriendList,
  useUpdateMyProfile,
} from '../../services/mutations';
import { useWindowSize } from '../../utils/useWindowSize';
import MyProfileAboutUs from '../MyProfileAbouUs';
import MyProfileMyInterests from '../MyProfileMyInterests';
import Loader from '../../molecule/Loader';
import MyProfileMyWork from '../MyProfileMyWork';
import MyProfileIDig from '../MyProfileIDig';
import MyPolitics from '../MyPolitics';
import MyProfileDream from '../MyProfileDream';
import MyProfileThingsOfSpirit from '../MyProfileThingsOfSpirit';
import MyProfileHobbiesAndFun from '../MyProfileHobbiesAndFun';
import MyProfileExcerciseAndSports from '../MyProfileExcerciseAndSports';
import MyProfileDreamJob from '../MyProfileDreamJob';

import MyProfileSectionView from '../MyProfileSectionView';
import MyProfilePhotos from '../MyProfilePhotos';
import MyProfileUpdate from '../MyProfileUpdate';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import SwitchEyeFill from '../../assets/SwitchEyeFill.svg';
import CustomSwitch from '../../atoms/CustomSwitch';
import { scaleByRatio } from '../../utils/helper';
import AddFriendFrom from '../../organism/AddFriendForm';
import MyProfileOthersData from '../MyProfileOthersData';
import close_icon from '../../assets/close_icon.svg';

import {
  ReportConvoCounter,
  ReportElbowCounter,
} from '../../utils/Context/reportCounter';
import CustomAlert from '../../atoms/CustomAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';

const MyProfile = ({ handleMenuClicked }) => {
  const location = useLocation();
  const localStorageItem = useLocalStorage();
  const getAllQuestions = useGetAllQuestions();
  const updateMyProfile = useUpdateMyProfile();
  const getMyProfile = useGetMyProfile();

  const getResponseForAllQuesionsByUser = useGetResponseForAllQuesionsByUser();
  const getUsersFriendList = useGetUsersFriendList();
  const reportCounterData = useGetRequestCount();
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [nameAgeLocationArray ,setNameAgeLocationArray ]= useState([]);

  const [showActiveFriend, setActiveFriend] = useState(false);
  // const [nameAgeLocation, setNameAgeLocation] = useState('');
  const [allQuestions, setAllQuestions] = useState(null);
  const [allAnswers, setAllAnswers] = useState(null);
  const showEditFromLocation =
    location?.state?.showEdit === true ? location?.state?.showEdit : false;
  const storedSetEdit = localStorage?.getItem('setEdit');
  const parsedSetEdit =
    storedSetEdit !== 'null' ? Boolean(JSON.parse(storedSetEdit)) : false;

  const isClickable = location?.state?.showEdit === true ? true : parsedSetEdit;
  const [clickable, setClickable] = useState(isClickable);

  const [editMode, setEditMode] = useState(false);
  const [renderUpdate, setrenderUpdate] = useState(false);
  const [showAddFriend, setShowAddFriend] = useState(false);
  const [selectedChipData, setSelectedChipdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [friendsList, setFriendsList] = useState([]);
  const [alertMsg, setAlertMsg] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [isActiveProfile, setIsActiveProfile] = useState(false);
  const [convoCount, setConvoCount] = useContext(ReportConvoCounter);
  const [elbowCount, setElbowCount] = useContext(ReportElbowCounter);
  const nav = useNavigate();
  const arrProfileSections = [
    {
      header: 'My Work',
      type: 'Questions',
      identifiers: [22, 23],
    },
    {
      header: 'I dig someone that.',
      type: 'Questions',
      identifiers: [7],
    },
    {
      header: 'My Politics',
      type: 'Questions',
      identifiers: [8],
    },
    {
      header: 'I dream of a person who.',
      type: 'Questions',
      identifiers: [2],
    },
    {
      header: 'Things of spirit.',
      type: 'Questions',
      identifiers: [12],
    },
    {
      header: 'Hobbies and fun.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'My deepest passion is.',
      type: 'Questions',
      identifiers: [3],
    },
    {
      header: 'Excercise and Sports.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'Dream Job.',
      type: 'Questions',
      identifiers: [24],
    },
    {
      header: 'Home and habbits.',
      type: 'Questions',
      identifiers: [10],
    },
    {
      header: 'I feel most at home when.',
      type: 'Questions',
      identifiers: [4],
    },
    {
      header: 'Culture and Media.',
      type: 'Questions',
      identifiers: [11],
    },
    {
      header: 'The most fun I remember when is.',
      type: 'Questions',
      identifiers: [6],
    },
    {
      header: 'To me my family is.',
      type: 'Questions',
      identifiers: [5],
    },
  ];

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const fetchAllQuestions = () => {
    getAllQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setAllQuestions(response?.data?.payload?.results);
          fetchAllAnswers();
        },
        onError: (error) => {
          setLoading(false);
        },
      },
    );
  };

  const fetchAllAnswers = () => {
    let answerData = {
      limit: 200,
      offset: 0,
      user: localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
    };
    getResponseForAllQuesionsByUser.mutate(answerData, {
      onSuccess: (response) => {
        let arrAnswer = response?.data?.payload?.results;
        setAllAnswers(arrAnswer);
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        setAllAnswers(null);
        setLoading(false);
      },
    });
  };

  const reportCount = () => {
    reportCounterData.mutate(
      {},
      {
        onSuccess: (response) => {
          setConvoCount(response?.data?.payload?.results[0]?.chat_with_dater_count);
          setElbowCount(response?.data?.payload?.results[0]?.reach_out_count);
        },
      },
      // onError: (err) => {
      //   console.log('getMyProfile onError: ', err);
      //   setLoading(false);
      // }
    );
  };
  const loadInitialdata = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile::', response?.data?.payload);
          setUserDetails(response?.data?.payload);
          // setNameAgeLocation(
          //   // response?.data?.payload?.user?.first_name +
          //   //   ', ' +
          //   //   response?.data?.payload?.age +
          //   //   ', ' +
          //   //   response?.data?.payload?.city,
          //   `${response?.data?.payload?.user?.first_name} ${
          //     response?.data?.payload?.age == null
          //       ? ''
          //       : ',' + response?.data?.payload?.age
          //   } ${response?.data?.payload?.city}`,
          // );
          setNameAgeLocationArray(
            [
              response?.data?.payload?.user?.first_name,
              response?.data?.payload?.age,
              response?.data?.payload?.city
            ]
          );
          setProfilePic(response?.data?.payload?.profile_pic || null);
          setUserImages(response?.data?.payload?.images || []);
          setIsActiveProfile(response?.data?.payload?.active);
          fetchAllQuestions();
          // console.log(
          //   'getMyProfile::data',
          //   nameAgeLocation,
          //   profilePic,
          //   userImages,
          //   isActiveProfile,
          // );
        },
        onError: (err) => {
          setLoading(false);
        },
      },
    );
  };

  const handlesetActiveBtn = () => {
    setActiveFriend(true);
  };

  const fetchFriendList = () => {
    setLoading(true);
    let data = {
      dater: localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
    };

    setrenderUpdate(true);
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setFriendsList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        setFriendsList([]);
      },
    });
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  useEffect(() => {
    reportCount();
  }, []);
  useEffect(() => {
    if (!editMode) {
      loadInitialdata();
    }
  }, [editMode]);

  useEffect(() => {
    let result = JSON.parse(localStorage?.getItem('selectedChipdata'));
    setSelectedChipdata(result);
  }, [editMode]);
  useEffect(() => {
    loadInitialdata();
  }, [clickable]);

  const handleChipClick = (chip) => {
    setEditMode(true);
    setSelectedChipdata(chip);
    localStorage.setItem('selectedChipdata', JSON.stringify(chip));
  };

  const handleActiveChange = (isActive) => {
    console.log('editmmode', editMode);
    if (isActive) {
      if (friendsList.length === 0) {
        setAlertMsg('Add a friend \n to activate your account.');
      } else {
        updateProfileActive(isActive);
      }
    } else {
      updateProfileActive(isActive);
    }
    // setIsActiveProfile(isActive)
  };
  const onAlertClose = () => {
    setAlertMsg('');
    setIsActiveProfile(false);
  };

  const updateProfileActive = (isActive) => {
    let data = {};
    if (isActive) {
      data = {
        active: true,
        onboardingCompleted: true,
      };
    } else {
      data = {
        active: false,
      };
    }
    setLoading(true);
    updateMyProfile.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        loadInitialdata();
      },
      onError: (error) => {
        console.log('updateProfileActive error:', error?.response?.data?.payload?.active[0]);
        setAlertMsg(error?.response?.data?.payload?.active[0]);
        setLoading(false);
      },
    });
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />
      {
      //true
      alertMsg.length > 0 
      && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
        // <CustomAlert
        //   isOpen={true}
        //   message={"Testing"}
        //   onClose={onAlertClose}
        // />
      )}
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 3,
        pt: scaleByRatio(3),
        marginTop: !showAddFriend
          ? isWideScreen
            ? `${scaleByRatio(120)}px`
            : `${scaleByRatio(85)}px`
          : '30px',
        background: '#000',
        color: '#fff',
      }}
    >
        {!editMode && !showAddFriend && allAnswers && (
          // allAnswers.length > 0 &&
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              sx={{
                // background: '#FFFFFF',
                background: '#000',
                width:'100%',
                //minHeight: `${scaleByRatio(50)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
                gap: '20px',
              }}
              className={styles.myProfileHeader}>
              <Grid item xs={getwidth > 1200 && 10} justifyContent="center">
                <Typography
                  textAlign="left"
                  className={styles.nameAddressHeader}>
                       <span className={styles.name}>{nameAgeLocationArray[0]}</span>{' '}
                        <span className={styles.ageAddress}>{nameAgeLocationArray[1]}, {nameAgeLocationArray[2]} </span>
                  {/* {nameAgeLocation} */}
                </Typography>
              </Grid>

              {/* <Grid
                item
                xs={getwidth > 1200 && 1.5}
                justifyContent="right"
                style={{
                  marginTop: `${scaleByRatio(8)}px`,
                  marginBottom: `${scaleByRatio(3)}px`,
                  marginLeft: '-15px',
                }}
                className={styles.activeBtn}>
                <CustomSwitch
                  txtSwitchOn={'Active'}
                  txtSwitchOff={'Inactive'}
                  checked={isActiveProfile}
                  handleChange={handleActiveChange}
                  iconThumb={SwitchEyeFill}
                />
              </Grid> */}
            </Grid>

            <Grid
                display={'flex'}
                justifyContent={'flex-end'}
                color={'#fff'}
                marginTop={'-34px'}
                marginBottom={'15px'}
                marginRight={'20px'}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={handleClose}
                className={styles.closeIcon}>
                <Typography>
                  <img src={close_icon}/>
                </Typography>
              </Grid>
            {/* <Grid
              display={'flex'}
              justifyContent={'flex-end'}
              color={'#fff'}
              marginTop={'-35px'}
              paddingBottom='10px'
              marginRight={'20px'}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
              onClick={handleClose}>
              <Typography color={'#fff'}>X</Typography>
            </Grid> */}

            <hr style={{ marginLeft: '20px', marginRight: '20px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />

            
            <Grid
              style={{
                textTransform:'uppercase',
                letterSpacing:'0.08em',
                lineHeight:'16px',
                fontWeight:'500',
                fontFamily:'Gill Sans Nova',
                fontSize:'14px',
                display: 'flex',
                justifyContent: 'center',
                color: '#44c8f5',
                padding: '0px 25px',
                paddingTop:'10px'
              }}>
              To become an active dater you must add atleast one friend to your
              account.
            </Grid>
            <Box
              component="main"
              bgcolor={'#000'}
              sx={{
                flexGrow: 1,
                pb: 9,
                pt: 3,
                pr: 3,
                pl: 3,
                // background: '#F3F3F3',
                background: '#000',
              }}>
              <MyProfileAboutUs
                profilePic={profilePic}
                allAnswers={allAnswers}
                isActiveProfileProps={isActiveProfile}
                userDetails={userDetails}
                clickable={clickable}
                handleChipClick={handleChipClick}
                setClickable={setClickable}
                friendsList={friendsList}
                handleMenuClicked={handleMenuClicked}
                handleActiveChange={handleActiveChange}
                setShowAddFriend={setShowAddFriend}
                showActiveBtn={true}
                handleSetActiveBtnState={handlesetActiveBtn}
              />
              <MyProfileMyInterests
                allAnswers={allAnswers}
                clickable={clickable}
                handleChipClick={handleChipClick}
              />
              {/*<MyProfilePhotos*/}
              {/*  arrImages={userImages}*/}
              {/*  clickable={clickable}*/}
              {/*  handleChipClick={handleChipClick}*/}
              {/*/>*/}

              {/* <MyProfileSectionView
                arrProfileSections={arrProfileSections}
                allAnswers={allAnswers}
                clickable={clickable}
                handleChipClick={handleChipClick}
              /> */}
              <MyProfileOthersData
                allQuestions={allQuestions}
                allAnswers={allAnswers}
                clickable={clickable}
                // setEditMode={setEditMode}
              />
            </Box>
          </>
        )}

        {editMode && (
          <>
            <MyProfileUpdate
              allQuestions={allQuestions}
              allAnwers={allAnswers}
              type={selectedChipData?.type}
              // type={'Profile'}
              QuestionID={selectedChipData?.QuestionID}
              identifier={selectedChipData?.identifier}
              setEditMode={setEditMode}
              userDetails={userDetails}
            />
          </>
        )}
        {showAddFriend && !loading && (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pb: 9,
              pt: 3,
              pr: 3,
              pl: 3,
              marginTop: `${scaleByRatio(150)}px`,
            }}>
            <AddFriendFrom onBackClicked={() => setShowAddFriend(false)}
            // topMargin={-40}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default MyProfile;
