import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import FlagUs from '../../assets/FlagUs.svg';
import FlagIndia from '../../assets/FlagIndia.svg';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import {
  routeUrls,
  ISD_CODE,
  errorMsg,
  startPageFlow,
} from '../../utils/constants';
import {
  useSendOTPInviteFriend,
  useVerifyOTPInviteFriend,
  useGetProfileDetailsByUID, useGetMyProfile, useGetActiveDater,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import CustomAlert from '../../atoms/CustomAlert';
import { Flag } from '@mui/icons-material';

const InviteFriend = () => {
  const location = useLocation();
  const nav = useNavigate();
  let { request_uid } = useParams();

  const sendOTP = useSendOTPInviteFriend();
  const verifyOTP = useVerifyOTPInviteFriend();
  const getProfileDetailsByUID = useGetProfileDetailsByUID();
  const getMyProfile = useGetMyProfile();
  const getActiveDater = useGetActiveDater();

  const localStorageItem = useLocalStorage();
  const [error, setError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phoneNum, setPhoneNum] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [otp5, setOtp5] = useState('');
  const [otp6, setOtp6] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const onMobileNumberChange = (e) => {};
  const getPhoneNumberByRequestID = () => {
    let data = {
      request_uid: request_uid,
    };
    setLoading(true);
    getProfileDetailsByUID.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        let status = response?.data?.payload?.status;
        if (status === 'P') {
          let phoneNum = response?.data?.payload?.phone_number;
          const country_code = phoneNum.substring(1, 2);
          console.log('country_code: ', country_code);
          setCountryCode(country_code);
          if (phoneNum.length > 10) {
            phoneNum = phoneNum.substring(phoneNum.length - 10);
            setPhoneNum(phoneNum);
          }
        } else {
          nav(routeUrls.join);
        }
      },
      onError: (error) => {
        console.log('getPhoneNumberByRequestID error: ', error);
        setLoading(false);
        // setError(
        //   error?.response?.data?.error || errorMsg.genericPhoneNumberError,
        // );
        setError('Invitation link is expired.');
      },
    });
  };
  const validatePhoneNumber = (phNum) => {
    console.log('validatePhoneNumber: ', phNum);
    let phRegex = /^[0-9]{10}$/;
    if (phRegex.test(phNum)) {
      setError('');
      return true;
    } else {
      setError('Please provide a valid phone number.');
      return false;
    }
  };
  const onNextClicked = (evt) => {
    console.log('....onNextClicked');
    if (validatePhoneNumber(phoneNum)) {
      console.log('Call api for mobile num:', ISD_CODE + phoneNum);
      let data = {
        request_uid: request_uid,
      };
      setLoading(true);
      sendOTP.mutate(data, {
        onSuccess: (response) => {
          console.log('sendOTP SUCCESS: ', response);
          setActiveStep(2);
          setTimeout(() => {
            document.getElementById(`OTP1`).focus();
          }, 200);
          setLoading(false);
        },
        onError: (err) => {
          console.log('sendOTP ERROR: ', err);
          console.log('sendOTP ERROR:######.... ');
          setError(
            err?.response?.data?.error || errorMsg.genericPhoneNumberError,
          );

          setLoading(false);
        },
      });
    }
  };
  const onOtp1Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp1(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP2`).focus();
        }
      } else {
        setOtp1('');
      }
    }
  };
  const onOtp2Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp2(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP3`).focus();
        }
      } else {
        setOtp2('');
      }
    }
  };
  const onOtp3Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp3(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP4`).focus();
        }
      } else {
        setOtp3('');
      }
    }
  };
  const onOtp4Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp4(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP5`).focus();
        }
      } else {
        setOtp4('');
      }
    }
  };
  const onOtp5Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp5(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP6`).focus();
        }
      } else {
        setOtp5('');
      }
    }
  };
  const onOtp6Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);

    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp6(e.target.value);
      } else {
        setOtp6('');
      }
    }
  };
  const validateOTPNumber = (value) => {
    let otpRegex = /^[0-9]{1}$/;
    if (otpRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };
  const resetOTP = () => {
    setOtp1('');
    setOtp2('');
    setOtp3('');
    setOtp4('');
    setOtp5('');
    setOtp6('');
  };

  const fetchActiveDaterOfFriend = () => {
    console.log('fetchActiveDaterOfFriend.....##');
    setLoading(true);
    getActiveDater.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log(
            'getActiveDater onSuccess##: ',
            response,
            response?.data?.payload?.user?.profile_pic,
          );
          localStorage.setItem(
            'activeDaterImage',
            response?.data?.payload?.user?.profile_pic,
          );
          localStorageItem.setLocalStorageItem(
            localStorageKeys.isLoggedIn,
            true,
          );
          nav(routeUrls.home);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getActiveDater onError: ', error);
        },
      },
    );
  };

  const fetchProfileData = () => {
    let data = {};
    getMyProfile.mutate(data, {
      onSuccess: (response) => {
        console.log('getMyProfile onSuccess: ', response);
        let _payload = response?.data?.payload;

        localStorageItem.setLocalStorageItem(
          localStorageKeys.profileId,
          _payload?.id,
        );

        localStorageItem.setLocalStorageItem('userType', 'friend');
        fetchActiveDaterOfFriend();
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };

  const onOTPNextClicked = () => {
    let finalOTP =
      otp1 + '' + otp2 + '' + otp3 + '' + otp4 + '' + otp5 + '' + otp6;
    let phRegex = /^[0-9]{6}$/;
    if (phRegex.test(finalOTP)) {
      setOtpError('');
      console.log('OTP API CALL: ', finalOTP);
      let data = {
        request_uid: request_uid,
        otp: finalOTP,
      };
      setLoading(true);
      verifyOTP.mutate(data, {
        onSuccess: (response) => {
          console.log('verifyOTP SUCCESS: ', response);
          setLoading(false);

          let token = response?.data?.payload?.token;
          localStorageItem.setLocalStorageItem(
            localStorageKeys.accessToken,
            token,
          );
          localStorage?.setItem('token', response?.data?.payload?.token);
          localStorage?.setItem(
            'profileImage',
            response?.data?.payload?.user?.profile_pic,
          );

          localStorageItem.setLocalStorageItem(
            localStorageKeys.isLoggedIn,
            true,
          );
          fetchProfileData()

        },
        onError: (err) => {
          console.log('verifyOTP ERROR: ', err);
          resetOTP();
          setOtpError(errorMsg.genericOTPError);
          setLoading(false);
        },
      });
    } else {
      setOtpError('Please provide a valid OTP.');
    }
  };
  const onAlertClose = () => {
    setAlertMsg('');
    // nav(routeUrls.join);
  };
  useEffect(() => {
    getPhoneNumberByRequestID();
  }, []);
  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <CentralContentPageLayout>
        {activeStep === 1 && (
          <CentralGridLayout>
                <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                marginBottom:'18px',
                width: `90%`,
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item>
                <Typography
                  textAlign="left"
                  className={styles.descriptionMessage}
                  style={{
                    lineHeight: `${scaleByRatio(22)}px`,
                    letterSpacing: '0.12em',
                    fontSize: `${scaleByRatio(18)}px`,
                    // lineHeight: `${scaleByRatio(40)}px`,
                    // width: '230px',
                  }}>
                  {/* Please confirm your Mobile number: */}
                  To start helping your friend on introw , we need to confirm
                  your phone number. Just touch send code.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item xs={12}>
                <Typography
                  textAlign="left"
                  className={styles.descriptionMessage}
                  style={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                  }}>
                  Mobile No
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: '10px',paddingTop: '10px' }}>
                <InputField
                  name="Code"
                  id={'Code'}
                  // value={'+1'}
                  value={countryCode == 9 ? `+${countryCode}1` : countryCode}
                  // placeholder={'Mobile Number'}
                  handleChange={onMobileNumberChange}
                  type={'text'}
                  class_name={styles.inputMobileNumber}
                  disabled={true}
                  prefix={
                    <img
                      src={countryCode == '9' ? FlagIndia : FlagUs}
                      alt="US"
                      style={{
                        height: `${scaleByRatio(24)}px`,
                        width: `${scaleByRatio(24)}px`,
                      }}
                    />
                  }
                  style={{
                    fontSize: `${scaleByRatio(20)}px`,
                    height: `${scaleByRatio(40)}px`,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="MobileNum"
                  id={'MobileNum'}
                  value={phoneNum}
                  placeholder={'Mobile Number'}
                  handleChange={onMobileNumberChange}
                  type={'text'}
                  class_name={styles.inputMobileNumber}
                  style={{
                    fontSize: `${scaleByRatio(20)}px`,
                    height: `${scaleByRatio(40)}px`,
                  }}
                  disabled={true}
                />
              </Grid>
              {error && error?.length > 0 && (
                <Grid item xs={12}>
                  <Typography
                    textAlign="left"
                    className={styles.error}
                    style={{
                      fontSize: `${scaleByRatio(18)}px`,
                      lineHeight: `${scaleByRatio(40)}px`,
                    }}>
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item>
                <Typography
                  textAlign="left"
                  className={styles.descriptionMessage}
                  style={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                  }}>
                  We’ll text you a verification code to confirm your
                  registration as a friend.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="flex-end">
              <Grid item>
                <CustomButton
                  text={'Send Code'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.nextButton}
                  customStyle={`yes`}
                  onClick={onNextClicked}
                />
              </Grid>
            </Grid>
          </CentralGridLayout>
        )}
        {activeStep === 2 && (
          <CentralGridLayout top='28%'>
             <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end">
              <Grid item className={styles.logoContainer}>
                <img
                  src={AppLogoMin}
                  alt={'App icon'}
                  // className={styles.logo}
                  style={{ height: `${scaleByRatio(90)}px`, marginBottom:'90px' }}
                />
              </Grid>
            </Grid> 
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item xs={12}>
                <Typography
                  textAlign="left"
                  className={styles.description}
                  style={{
                   fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                    marginBottom: `${scaleByRatio(20)}px`,
                  }}>
                  Enter Your Verification Code
                </Typography>
              </Grid>

              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP1"
                  id={'OTP1'}
                  value={otp1}
                  placeholder={''}
                  handleChange={onOtp1Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="1"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP2"
                  id={'OTP2'}
                  value={otp2}
                  placeholder={''}
                  handleChange={onOtp2Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="2"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP3"
                  id={'OTP3'}
                  value={otp3}
                  placeholder={''}
                  handleChange={onOtp3Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="3"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP4"
                  id={'OTP4'}
                  value={otp4}
                  placeholder={''}
                  handleChange={onOtp4Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="4"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP5"
                  id={'OTP5'}
                  value={otp5}
                  placeholder={''}
                  handleChange={onOtp5Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="5"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP6"
                  id={'OTP6'}
                  value={otp6}
                  placeholder={''}
                  handleChange={onOtp6Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="6"
                />
              </Grid>
              {otpError && otpError?.length > 0 && (
                <Grid item xs={12}>
                  <Typography textAlign="left" className={styles.error}>
                    {otpError}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="flex-end">
              <Grid item>
                <CustomButton
                  text={'Submit'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.primary}
                  onClick={onOTPNextClicked}
                />
              </Grid>
            </Grid>
          </CentralGridLayout>
        )}
      </CentralContentPageLayout>
    </>
  );
};

export default InviteFriend;
